html,
body {
  background: black;
  color: rgba(255, 255, 255, 0.8);
  font-family: Courier;
  font-size: 16px;
}

button:focus, a:focus, :focus {
  @apply outline-none;
  color: crimson;
}
